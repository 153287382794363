;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"F8hYLwuTSayxYJjTBPfhs"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from "@sentry/nextjs"
import { ApiError } from "shared/api/back/_generated/runtime"

Sentry.init({
  dsn: "https://6ab658e3aabef9ba3a1ed978eac97393@sentry.unicorngo.ru/3",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  async beforeSend(event, hint) {
    const error = hint.originalException

    if (error instanceof Error && error.message === "not authed") return null

    if (error instanceof ApiError) {
      const resp = error.response

      let body = error.request.body

      if (typeof error.request.body === "string") {
        try {
          body = JSON.parse(error.request.body)
        } catch (ignore) {}
      }

      event.extra = {
        url: error.url,
        body: body,
        method: error.request.method,
        headers: error.request.headers,
        response: resp.headers.get("Content-Type")?.includes("application/json")
          ? await resp.json()
          : await resp.text(),
      }

      event.message = "API Error"

      // Изменяем имя ошибки
      if (event.exception?.values?.length) {
        event.exception.values[0].type = `API error`
        event.exception.values[0].value = `${resp.status} ${resp.statusText} on ${error.url}`
      }
    }
    return event
  },
})
